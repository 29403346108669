(function () {
    angular.module('informaApp')
        .controller('HeaderController', HeaderController);

    function HeaderController($scope, $location, ConstantsSvc, AuthenticationService, OmnitureSvc) {
        $scope.isLoggedIn = () => AuthenticationService.isLoggedIn();

        $scope.isAdmin = () => {
            const user = AuthenticationService.getCurrentUser();

            return user && user.isAdmin;
        };

        $scope.goToAdminPage = () => {
            $location.path(ConstantsSvc.urls.admin);
        };

        $scope.getUserEmail = () => {
            const user = AuthenticationService.getCurrentUser();

            return user ? user.email : 'unknown';
        };

        $scope.hasAccess = () => {
            const user = AuthenticationService.getCurrentUser();

            return user && user.hasAccess;
        };

        $scope.contactClientServiceClick = () => {
            OmnitureSvc.trackContactClientService();
        };

        $scope.logout = () => {
            $location.path(ConstantsSvc.urls.logoutRedirect);
        };

        $scope.getMyProfileURL = () => {
            return `${window.envVariables.authzero.myprofile}?source=${encodeURIComponent(location.protocol + "//" + location.host +"/table")}`;
        };
    }
})();
